<template>
  <v-container>
    <specific_card
      width="600"
      name="Payment data"
      :export_name="payment['Client_Associations'] + 'payment'"
      :value="value"
      @input="$emit('input', $event)"
    >
      <v-list dense>
        <v-list-item
          v-for="({ name, value, link }, index) in columns"
          :key="index"
          @click="link ? manageContact() : () => 1"
        >
          <v-list-item-title>{{ name }}</v-list-item-title>
          <v-list-item-subtitle>{{
            valueFormatter(name, payment[value])
          }}</v-list-item-subtitle>
        </v-list-item>
      </v-list>
    </specific_card>
  </v-container>
</template>

<script>
import { dateFormat, moneyFormat } from "@/functions";
export default {
  name: "payment",
  components: { specific_card: () => import("@/components/specific_card") },
  data() {
    return {
      columns: [
        {
          name: "Payment Amount",
          value: "Payment Amount"
        },
        {
          name: "Payment Date",
          value: "Payment Date"
        },
        {
          name: "Home Owner",
          value: "Home Owner"
        },
        {
          name: "Management Company",
          value: "Management Company"
        },
        {
          name: "Collection Attorney",
          value: "Collection Attorney",
          link: true
        },
        {
          name: "Pre-Litigation Paralegal",
          value: "Pre-Litigation Paralegal",
          link: true
        },
        {
          name: "General Attorney",
          value: "General Attorney",
          link: true
        },
        {
          name: "Litigation Paralegal",
          value: "Litigation Paralegal",
          link: true
        },
        {
          name: "Memo",
          value: "Memo"
        }
      ],
      payment: {}
    };
  },
  watch: {
    current: {
      immediate: true,
      handler(val) {
        if (val && Object.keys(val).length > 0) {
          this.payment = val;
        } else {
          this.payment = {};
        }
      }
    }
  },
  methods: {
    valueFormatter(name, val) {
      if (val || val === 0) {
        if (name === "Payment Date") {
          return dateFormat(new Date(val));
        }
        if (name === "Payment Amount") {
          return moneyFormat(val);
        }
        return val;
      }
      return null;
    },
    manageContact() {
      this.$store.commit("lauderSetSelectedAss", {
        Client_Associations: this.association["Client_Associations"]
      });
      // this.$store.dispatch("assChanged");
      this.$router.push({ name: "lauderLaw---contacts" });
      this.$emit("input", false);
      // let as = this.associations()[
      //   this.associations().findIndex(
      //     r => r.Client_Associations === this.payment.Client_Associations
      //   )
      // ];
      // this.$store.commit("lauderSetSelectedAss", as);
      // this.$emit("input", false);
      // this.$router.push({ name: "lauderLaw---contacts" });
    }
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    current: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style scoped></style>
